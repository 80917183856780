import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Link } from "gatsby"
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NoImage from "../images/wards-no-image-tile.png"


const SavedProperties = (props) => {

  const [properties, setProperties] = useState([]);
  const [render, setRender] = useState(false);

  useEffect(() => {
    let cookieProperty = localStorage.getItem('cookieProperty');
    if (cookieProperty && cookieProperty.length > 0 && !render) {
      cookieProperty = JSON.parse(cookieProperty);
      console.log('cookieProperty', cookieProperty)
      setProperties(cookieProperty);
      setRender(true)
    }
  }, [])

  const removeCookieProperty = (property) => { 
    let cookieProperty = localStorage.getItem('cookieProperty');
    if(cookieProperty){
      cookieProperty = JSON.parse(cookieProperty); 
      let isExist = cookieProperty.find((item)=>item.crm_id == property.crm_id)
      if(isExist){
        cookieProperty = cookieProperty.filter((item)=>item.crm_id != property.crm_id)
        localStorage.setItem('cookieProperty', JSON.stringify([...cookieProperty]));
        setProperties(cookieProperty)
      } 
    }  
  }


  return (
    <div className="wrapper error-details ">
      <Header />
      <Seo title="Saved Properties" />
      <div className="saved-property-page">
        <Container>
          <Row>
            <Col lg={12} className="main-banner content">
              <h2>Saved Properties</h2>

              {
                properties && properties.map((item) => { 
                  var image_url = NoImage 

                  if (item.images1?.images && item.images1?.images.length > 0) {
                    let images = Object.values(item.images1.images[0]); 
                    image_url = images[0];
                  }
                  var url = ''
                  if (item.search_type === "sales") {
                    url = '/property-for-sale/' + item.slug + '-' + item.crm_id + '/'
                  }
                  else if (item.search_type === "lettings") {
                    url = '/property-to-rent/' + item.slug + '-' + item.crm_id + '/'
                  }

                  return (
                    <div className="saved-properties" >
                      <div className="details">
                        <div className="image">
                          <img src={image_url} alt="img" />
                        </div>
                        <div className="content-box">
                          <h4>
                            <Link to={`${url}`}> {item.display_address}</Link>
                          </h4>
                          {item.price_qualifier !== "POA" ? <span className="property-price">£{item.price ? item.price.toLocaleString() : 0} {item.search_type == "lettings" ? ' PCM' : ''}</span> : <span className="property-price">Price on Application</span>}
                        </div>
                      </div>

                      <div className="remove">
                        <button className="btn" onClick={()=>{removeCookieProperty(item)}}>Remove</button>
                      </div>
                    </div>
                  )
                })
              }

              {/* <div className="btn-wrap">
                <Link class="btn btn-secondary" to={VALUATION_PAGE_URL.alias}>
                  <span>Selling or letting?</span>Book a valuation
                </Link>
                <Link class="btn" to={FIND_A_PROPERTY_PAGE_URL.alias}>
                  <span>Buying or renting?</span>Our live properties
                </Link>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />

    </div>
  )
}


export default SavedProperties
